import { useState, useEffect } from 'react'
import logo from './logo.svg';
import './App.css';
import {Table, Button} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsDown , faThumbsUp  } from '@fortawesome/free-solid-svg-icons'

function App() {
    const [events, setEvents] = useState([])
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
      const getEvents = async () => {
        const eventsFromServer = await fetchEvents()
        setEvents(eventsFromServer)
      }

      getEvents()
    }, [])

    const fetchEvents = async () => {
      const res = await fetch('https://9iob1zofqc.execute-api.eu-west-2.amazonaws.com/Dev')
      const data = await res.json()
      return data
    }

    const acceptEvent = async (id) => {
        console.log(id);
    }

    const rejectEvent = async (id) => {
        console.log(id);
    }

    const deleteEvent = async (id) => {
        console.log(id);
        let updatedEvents = [...events].filter (i => i.id !== id)
        setEvents(updatedEvents)

        // const res = await fetch(`http://localhost:5000/Events/${id}`, {
        //     method: 'DELETE',
        // })
        // //We should control the response status to decide if we will change the state or not.
        // res.status === 200
        //     ? setTasks(tasks.filter((task) => task.id !== id))
        //     : alert('Error Deleting This Task')
    }

  return (
    <div className="App">
      <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <p>Riviso</p>
          {isLoading ? <div>Loading</div> :
              <div className="container">

                  <div className="row">
                      <div className="col-12">
                          <h4>Events</h4>
                      </div>
                  </div>

                  <div className="row">
                      <div className=".col-xs-12 center text-center">
                          <Table dark responsive striped bordered hover>
                              <thead>
                              <tr>
                                  <th>ID</th>
                                  <th>Submitter</th>
                                  <th>Group</th>
                                  <th>Date</th>
                                  <th>Status</th>
                                  <th colSpan="3">Actions</th>
                              </tr>
                              </thead>
                              <tbody>
                              {events.length === 0 ? <td colSpan="9">All caught up!</td> :
                                  events.map(event =>
                                      <tr key={event.id}>
                                          <td>{event.id}</td>
                                          <td>{event.submitter}</td>
                                          <td>{event.group}</td>
                                          <td>{event.Date}</td>
                                          <td>{event.status}</td>
                                          <td><Button className="btn btn-lg btn-success"
                                                      onClick={() => acceptEvent(event.id)}> <FontAwesomeIcon
                                              icon={faThumbsUp}/> Accept </Button></td>
                                          <td><Button className="btn btn-lg btn-warning"
                                                      onClick={() => rejectEvent(event.id)}> <FontAwesomeIcon
                                              icon={faThumbsDown}/> REJECT </Button></td>
                                          <td><Button className="btn btn-lg btn-danger"
                                                      onClick={() => deleteEvent(event.id)}> <FontAwesomeIcon
                                              icon={faThumbsDown}/> DELETE </Button></td>
                                      </tr>
                                  )
                              }
                              </tbody>
                          </Table>
                      </div>
                  </div>
              </div>
          }
      </header>
    </div>
  );
}

export default App;
